import { quotationGql } from "@/api/quotations/quotation";
import {
  Quotation,
  QuotationVariables,
  Quotation_quotation_Quotation,
} from "@/api/quotations/__generated__/Quotation";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { Ref, computed, onMounted } from "vue";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";

export type UseQuotationOptions = {
  quotationId?: Ref<string>;
  quotationUuid?: Ref<string>;
};

export const useQuotation = (options: UseQuotationOptions) => {
  const {
    currentSubscriptionPlan,
    refetchSubscriptionPlans,
    getSubscriptionPlanByLimits,
  } = useSubscriptionPlans(true);

  // === Queries ===
  const {
    result: quotationResult,
    loading: quotationLoading,
    refetch: refetchQuotation,
  } = useQuery<Quotation, QuotationVariables>(
    quotationGql,
    () => ({
      input: {
        quotationId: options.quotationId?.value,
        quotationUuid: options.quotationUuid?.value,
      },
    }),
    () => ({
      enabled: !!options.quotationId?.value || !!options.quotationUuid?.value,
    })
  );

  const quotationParsedResponse = computed(() =>
    parseGqlResponse<Quotation_quotation_Quotation>(
      "Quotation",
      quotationResult.value,
      HideIfErrorCode.ALL_ERRORS
    )
  );

  const quotation = computed(() => {
    return quotationParsedResponse.value.data;
  });

  const quotationSubscriptionPlan = computed(() =>
    getSubscriptionPlanByLimits(
      quotation.value?.numOfVideos,
      quotation.value?.numOfPerformers
    )
  );

  const quotationEditable = computed(
    () =>
      ![OrderStatus.DONE, OrderStatus.CANCELED].includes(
        quotation.value?.order?.status?.key as OrderStatus
      )
  );

  const maxPerformerCount = computed(
    () =>
      quotation.value?.numOfPerformers ??
      currentSubscriptionPlan.value?.maxPerformerCount ??
      0
  );

  onMounted(() => {
    refetchSubscriptionPlans();
    refetchQuotation();
  });

  return {
    quotationParsedResponse,
    quotation,
    quotationLoading,
    refetchQuotation,

    quotationSubscriptionPlan,
    quotationEditable,
    maxPerformerCount,
  };
};
