import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "performer-details-page" }
const _hoisted_2 = {
  key: 1,
  class: "grid grid-cols-1 md:grid-cols-5 gap-10 mt-10"
}
const _hoisted_3 = { class: "mt-12" }
const _hoisted_4 = { class: "performer-details-page__details" }
const _hoisted_5 = { class: "performer-details-page__stats" }
const _hoisted_6 = { class: "mt-12" }
const _hoisted_7 = {
  key: 0,
  class: "mt-12"
}
const _hoisted_8 = { class: "performer-details-page__gallery grid grid-cols-2 md:grid-cols-4 gap-4 mb-8" }
const _hoisted_9 = {
  key: 1,
  class: "mt-12"
}
const _hoisted_10 = {
  key: 2,
  class: "mt-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_VideoEmbedCarousel = _resolveComponent("VideoEmbedCarousel")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_PerformerRating = _resolveComponent("PerformerRating")!
  const _component_PerformerBadgeList = _resolveComponent("PerformerBadgeList")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (!!_ctx.performerDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.showTiktokColumn)
              ? (_openBlock(), _createBlock(_component_a_col, {
                  key: 0,
                  class: "col-span-full lg:col-span-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_VideoEmbedCarousel, {
                      "embed-urls": _ctx.performerDetails.performerProfile?.videoUrls ?? [],
                      class: "m-auto"
                    }, null, 8, ["embed-urls"]),
                    _createElementVNode("div", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "share-link")
                    ])
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_col, {
              class: _normalizeClass(["col-span-full lg:col-span-3", [!_ctx.showTiktokColumn && 'lg:col-span-full']])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Avatar, {
                    "avatar-src": _ctx.performerDetails.performerProfile?.avatar,
                    class: "w-32 sm:w-52"
                  }, null, 8, ["avatar-src"]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_a_typography_title, {
                      level: 2,
                      class: "mb-2"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.performerDetails.performerProfile?.nickname), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_PerformerRating, {
                      performer: _ctx.performerDetails,
                      class: "mb-4"
                    }, null, 8, ["performer"]),
                    _createVNode(_component_PerformerBadgeList, {
                      "performer-profile": _ctx.performerDetails.performerProfile,
                      "disable-locked-state": _ctx.disableLockedState,
                      class: "my-4"
                    }, null, 8, ["performer-profile", "disable-locked-state"]),
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performerStats, (detail) => {
                        return (_openBlock(), _createBlock(_component_IconLabel, {
                          key: detail.value,
                          text: `${detail.label} ${detail.value}`
                        }, _createSlots({ _: 2 }, [
                          (detail.iconName)
                            ? {
                                name: "icon",
                                fn: _withCtx(() => [
                                  _createVNode(_component_AppIcon, {
                                    name: detail.iconName
                                  }, null, 8, ["name"])
                                ])
                              }
                            : undefined
                        ]), 1032, ["text"]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_typography_title, { level: 5 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("About")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_paragraph, {
                    innerHTML: _ctx.performerDetails.performerProfile?.introduction,
                    class: "performer-details-page__description"
                  }, null, 8, ["innerHTML"])
                ]),
                ((_ctx.performerDetails.performerProfile?.images ?? []).length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_a_typography_title, { level: 5 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Gallery")), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performerDetails.performerProfile?.images, (image) => {
                          return (_openBlock(), _createBlock(_component_a_image, {
                            key: image?.image,
                            src: image?.thumbnail ?? '#',
                            preview: {
                src: image?.image ?? '#',
              }
                          }, null, 8, ["src", "preview"]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!!_ctx.performerDetails.performerProfile?.feedback)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_a_typography_title, { level: 5 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Feedback")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_paragraph, {
                        innerHTML: _ctx.performerDetails.performerProfile?.feedback,
                        class: "performer-details-page__description"
                      }, null, 8, ["innerHTML"])
                    ]))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "extra"),
                (!_ctx.showTiktokColumn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _renderSlot(_ctx.$slots, "share-link")
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["class"])
          ]))
        : _createCommentVNode("", true)
  ]))
}