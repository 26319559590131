import gql from "graphql-tag";

export const confirmPerformersInOrderGql = gql`
  mutation ConfirmPerformersInOrder($input: ConfirmPerformersInOrderInput!) {
    confirmPerformersInOrder(input: $input) {
      ... on GenericSuccess {
        __typename
        message
        success
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;
