import { IconLabelColor } from "@/shared/components/IconLabel.vue";
import { AppIconName } from "@/shared/components/Icons/AppIcon.vue";
import { UsersPerformerProfileGenderChoices } from "@/../__generated__/globalTypes";
import { i18nTranslate } from "@/plugins/i18n";
import { Performers_performers_PerformerResults_results_performerProfile } from "@/api/performer/__generated__/Performers";
import { useConfig } from "@/shared/composables/useConfig";
import { computed } from "vue";

/** Gets if Rookie or not based on BE's ID config */
const { configValue: rookieBadgeIdsValue } = useConfig("BADGE_ROOKIE_IDS");
export const rookieBadgeIds = computed<string[]>(
  () => rookieBadgeIdsValue.value?.split(",") ?? []
);

/**
 * Type that contains only necessary fields for utility functions,
 * to increase compatibility with generated types.
 */
type PerformerProfile = Pick<
  Performers_performers_PerformerResults_results_performerProfile,
  "badges"
>;

export type PerformerBadgeData = {
  keywords: string[];
  color: IconLabelColor;
  iconName?: AppIconName;
};

/** Mapping of extra data for performer badge not present in BE
 * NOTE: Use lowercase for keys
 */
export const performerBadgeData: PerformerBadgeData[] = [
  {
    keywords: ["top performer", "top creator"],
    color: "red",
    iconName: "crownRed",
  },
  {
    keywords: ["recommended"],
    color: "pink",
    iconName: "starPink",
  },
  {
    keywords: ["great camera"],
    color: "blue",
    iconName: "cameraBlue",
  },
  {
    keywords: ["silver performer", "silver creator"],
    color: "silver",
    iconName: "gemSilver",
  },
  {
    keywords: ["bronze performer", "bronze creator"],
    color: "bronze",
    iconName: "gemBronze",
  },
];

/**
 * Gets extra data for a badge that is not returned by BE
 * @param name The string name of the badge
 */
export const getPerformerBadgeData = (name: string): PerformerBadgeData => {
  const badgeData = performerBadgeData.find((badge) =>
    badge.keywords.some((keyword) =>
      name.toLowerCase().includes(keyword.toLowerCase())
    )
  );

  // Default data
  return (
    badgeData ?? { keywords: [], iconName: "checkmarkBlue", color: "blue" }
  );
};

/**
 * Gets the appropriate pronoun based on gender.
 * @param gender A `UsersPerformerProfileGenderChoices` enum.
 * @returns Pronoun string in lowercase.
 */
export const getPerformerPronoun = (
  gender: UsersPerformerProfileGenderChoices | undefined
): string => {
  switch (gender) {
    case UsersPerformerProfileGenderChoices.MALE:
      return i18nTranslate("him");
    case UsersPerformerProfileGenderChoices.FEMALE:
      return i18nTranslate("her");
    default:
      return i18nTranslate("them");
  }
};

/** Used in performer's stats i.e. deliveryTime, rating, videoCount, etc */
export type PerformerStat = {
  label?: string | number | null;
  /** Value can be optional so it can be automatically hidden if undefined  */
  value?: string | number | null;
  /** Icons present in AppIcon component */
  iconName: AppIconName;
};

/** Cross-check if PerformerProfile object has any of the `badgeIds` */
export const hasBadgeIds = (
  performerProfile: PerformerProfile | null | undefined,
  badgeIds: string[]
): boolean => {
  return (
    performerProfile?.badges?.some((badge) =>
      badgeIds.includes(badge?.id ?? "")
    ) ?? false
  );
};

export const isPerformerRookie = (
  performerProfile: PerformerProfile | null | undefined
): boolean => {
  return hasBadgeIds(performerProfile, rookieBadgeIds.value);
};
