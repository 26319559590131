import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createBlock(_component_a_typography_text, {
    class: _normalizeClass(["icon-label", [
      _ctx.color && `icon-label--${_ctx.color}`,
      _ctx.uppercase && 'uppercase',
      _ctx.strong && 'font-bold',
    ]])
  }, {
    default: _withCtx(() => [
      (!!_ctx.$slots['icon'])
        ? _renderSlot(_ctx.$slots, "icon", { key: 0 })
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
    ]),
    _: 3
  }, 8, ["class"]))
}