
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import PerformerDetailsDisplay from "@/shared/components/PerformerDetails/PerformerDetailsDisplay.vue";
import { useOrder } from "@/shared/composables/useOrder";
import { usePerformerDetails } from "@/shared/composables/usePerformerDetails";
import { useQuotation } from "@/shared/composables/useQuotation";
import { useQuotationPrices } from "@/shared/composables/useQuotationPrices";
import routeNames from "@/web/router/routeNames";
import { fallbackSrc, galleryPlaceholderSrc } from "@/shared/utils/constants";
import message from "@/shared/utils/message";
import { getPerformerPronoun } from "@/shared/utils/performerProfileHelper";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { createRedirectedRoute } from "@/shared/utils/routerUtils";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const id = String(route.params.id);
    const isPublicRoute = String(route.query.public) === "true";

    /** Backwards compatibility on old public route. See: #865cybetz */
    onMounted(() => {
      // If public, redirect to new route `publicPerformerDetails`
      if (isPublicRoute) {
        router.replace({
          name: routeNames.publicPerformerDetails,
          params: { id: route.params.id },
        });
      }
      // Else, check if not authenticated. Require login.
      else if (!store.state.auth.isAuthenticated) {
        router.replace(
          createRedirectedRoute({ name: routeNames.login }, route.fullPath)
        );
      }
    });

    const { getFormattedQuotationPrice } = useQuotationPrices();

    const {
      order,
      selectedPerformers,
      addPerformer,
      addPerformerToOrderLoading,
      removePerformer,
      removePerformerFromOrderLoading,
      canUpdatePerformerList,
      checkCanAddMorePerformers,
    } = useOrder({
      orderId: ref(String(route.query.orderId)),
    });

    const { maxPerformerCount } = useQuotation({
      quotationId: computed(() => order.value?.quotation?.id ?? ""),
    });

    const canAddMorePerformers = computed(() =>
      checkCanAddMorePerformers(maxPerformerCount.value)
    );

    const { performerDetails, performerDetailsLoading } = usePerformerDetails({
      input: { id },
    });

    const isPerformerSelected = computed<boolean>(
      () => !!selectedPerformers.value.find((performer) => performer.id === id)
    );

    const handleAddPerformer = async () => {
      const response = await addPerformer({
        orderId: order.value?.id,
        userId: id,
      });

      if (response?.success) {
        message["success"](
          response.message ??
            t("Successfully added this model creator to your order.")
        );
      }
    };

    const handleRemovePerformer = async () => {
      const response = await removePerformer({
        orderId: order.value?.id,
        userId: id,
      });

      if (response?.success) {
        message["success"](
          response.message ??
            t(
              "Successfully canceled request for working with this model creator."
            )
        );
      }
    };

    const handlePerformerActionClick = () => {
      if (isPerformerSelected.value) {
        handleRemovePerformer();
      } else {
        handleAddPerformer();
      }
    };

    return {
      t,
      performerDetails,
      performerDetailsLoading,
      galleryPlaceholderSrc,
      isPerformerSelected,
      addPerformerToOrderLoading,
      removePerformerFromOrderLoading,
      handlePerformerActionClick,
      isPerformerButtonVisible: computed(() => !!order.value),
      isPerformerButtonDisabled: computed<boolean>(() => {
        return (
          // Performer is locked
          performerDetails.value?.performerProfile?.isLocked ||
          // Disabled due to OrderStatus greater than SELECT_CREATOR
          !canUpdatePerformerList.value ||
          // Prevents disabled button for "Cancel Request"
          (!canAddMorePerformers.value && !isPerformerSelected.value)
        );
      }),
      canUpdatePerformerList,
      premiumPerformerCardText: computed(() => {
        return t(
          "{performerName} is a premium model creator. If you choose to work with {pronoun}, you will be paying an additional {price} per video.",
          {
            performerName: performerDetails.value?.performerProfile?.nickname,
            pronoun: getPerformerPronoun(
              performerDetails.value?.performerProfile?.gender
            ),
            price: getFormattedQuotationPrice(
              "ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE"
            ),
          }
        );
      }),
      fallbackSrc,
      showMaxPerformerNote: computed(
        () =>
          canUpdatePerformerList.value &&
          !canAddMorePerformers.value &&
          // Only show note on unselected PC,
          // because selected PCs will have Cancel Request button
          !isPerformerSelected.value
      ),
      selectedPerformers,
      maxPerformerCount,
    };
  },
  components: {
    PageLayout,
    AppIcon,
    PerformerDetailsDisplay,
  },
});
