import gql from "graphql-tag";

export const quotationGql = gql`
  query Quotation($input: QuotationInput!) {
    quotation(input: $input) {
      ... on Quotation {
        id
        created
        order {
          id
          status {
            id
            key
          }
        }
        status {
          id
          key
        }
        user {
          id
          username
        }
        hasSubmitted
        choosePerformers
        performersPreference
        returnProducts
        sendProducts
        useSameDayDelivery
        useExpress
        useExtendedVideoLength
        usePerformerTravel
        usePremiumPerformers
        hireStrategist
        numOfVideos
        numOfPerformers
        promoCode {
          id
          code
          expiry
          isActive
          durationInMonths
          discountPerVideo
          discountFromTotal
        }
        totalPrice
        useTestimonialPackage
        testimonialLockInMonths
        revisionRequests
        pricesJson
        priceMatrix
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
