import get from "lodash/get";

/**
 * Orders a list of items using an array of priority list.
 * Items in priority list are placed in front of the resulting array.
 * @param list The list to order
 * @param priorityList The list of string values to prioritize in array when found.
 * @param propertyPath The path to traverse an object's property to get the lookup value.
 */
export const orderByPriority = <T>(
  list: T[],
  priorityList: string[],
  propertyPath?: string
): T[] => {
  let orderedCount = 0;
  const _arrayCopy = [...list];

  for (const priorityItem of priorityList) {
    const foundIndex = _arrayCopy.findIndex(
      (element) =>
        String(
          propertyPath ? get(element, propertyPath, "") : element
        ).toLowerCase() === priorityItem.toLowerCase()
    );

    if (foundIndex >= 0) {
      // Remove
      const removed = _arrayCopy.splice(foundIndex, 1);
      // Add back
      _arrayCopy.splice(orderedCount, 0, ...removed);

      orderedCount += 1;
    }
  }

  return _arrayCopy;
};
