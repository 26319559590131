import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "performer-badge-list flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_IconLabel = _resolveComponent("IconLabel")!

  return (_ctx.performerProfile?.isPremium || _ctx.badges.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLocked)
          ? (_openBlock(), _createBlock(_component_IconLabel, {
              key: 0,
              text: _ctx.t('Fully Booked'),
              color: "gray",
              uppercase: "",
              strong: ""
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_AppIcon, { name: "restricted" })
              ]),
              _: 1
            }, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.performerProfile?.isPremium)
          ? (_openBlock(), _createBlock(_component_IconLabel, {
              key: 1,
              text: _ctx.t('Premium Model Creator'),
              color: _ctx.isLocked ? 'gray' : 'gold',
              uppercase: "",
              strong: ""
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_AppIcon, {
                  name: "diamondGold",
                  class: _normalizeClass([_ctx.isLocked && 'filter grayscale'])
                }, null, 8, ["class"])
              ]),
              _: 1
            }, 8, ["text", "color"]))
          : _createCommentVNode("", true),
        (_ctx.badges.length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.badges, (badge) => {
              return (_openBlock(), _createBlock(_component_IconLabel, {
                key: badge?.name,
                text: badge?.name ?? '',
                color: 
          _ctx.isLocked ? 'gray' : _ctx.getPerformerBadgeData(badge?.name ?? '').color
        ,
                uppercase: "",
                strong: ""
              }, {
                icon: _withCtx(() => [
                  (_ctx.getPerformerBadgeData(badge?.name ?? '').iconName)
                    ? (_openBlock(), _createBlock(_component_AppIcon, {
                        key: 0,
                        name: _ctx.getPerformerBadgeData(badge?.name ?? '').iconName,
                        class: _normalizeClass([_ctx.isLocked && 'filter grayscale'])
                      }, null, 8, ["name", "class"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["text", "color"]))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}