
import { PerformerDetails_performerDetails_PerformerDetails } from "@/api/performer/__generated__/PerformerDetails";
import Avatar from "@/shared/components/Avatar.vue";
import IconLabel from "@/shared/components/IconLabel.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import PerformerBadgeList from "@/shared/components/Performers/PerformerBadgeList.vue";
import PerformerRating from "@/shared/components/Performers/PerformerRating.vue";
import VideoEmbedCarousel from "@/shared/components/VideoEmbed/VideoEmbedCarousel.vue";
import { PerformerStat } from "@/shared/utils/performerProfileHelper";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    performerDetails:
      Object as PropType<PerformerDetails_performerDetails_PerformerDetails>,
    loading: Boolean,
    disableLockedState: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const performerDetails = computed(() => props.performerDetails);

    const showTiktokColumn = computed(
      () => !!performerDetails.value?.performerProfile?.videoUrls?.length
    );

    /** Stats shown below name */
    const performerStats = computed(
      () =>
        [
          {
            label: t("Videos Made:"),
            value: performerDetails.value?.performerProfile?.videoCount,
            iconName: "playButton",
          },
          {
            label: t("Average Delivery Days:"),
            value: performerDetails.value?.performerProfile?.deliveryTime,
            iconName: "clock",
          },
          {
            label: t("Location:"),
            value: performerDetails.value?.performerProfile?.location,
            iconName: "location",
          },
        ].filter((stat) => !!stat.value) as PerformerStat[]
    );

    return { t, showTiktokColumn, performerStats };
  },
  components: {
    VideoEmbedCarousel,
    AppIcon,
    IconLabel,
    Avatar,
    PerformerBadgeList,
    PerformerRating,
  },
});
