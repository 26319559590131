import gql from "graphql-tag";

export const orderGql = gql`
  query Order($input: IdInput!) {
    order(input: $input) {
      ... on Order {
        id
        created
        targetDate
        status {
          id
          name
          key
        }
        items {
          id
          title
          thumbnail
          script
        }
        quotation {
          id
          created
          numOfVideos
          numOfPerformers
          hireStrategist
          useTestimonialPackage
          choosePerformers
          status {
            id
            key
          }
        }
        performers {
          id
          name
          performerProfile {
            nickname
            avatar
          }
        }
        performerApplications {
          id
          performer {
            id
            performerProfile {
              nickname
              badges {
                id
                name
              }
              industries {
                id
                name
              }
              rating
              isPremium
              isLocked
              avatar
            }
          }
        }
        notes
        creatorsLink
        scriptLink
        videoOutputLink
        feedbackLink
        paymentLink
        shipmentInstructions
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
