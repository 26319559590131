
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export type IconLabelColor =
  | "blue"
  | "pink"
  | "red"
  | "gray"
  | "gold"
  | "silver"
  | "bronze";

export default defineComponent({
  props: {
    color: {
      type: String as PropType<IconLabelColor>,
    },
    text: {
      type: String as PropType<string | number>,
      required: true,
    },
    uppercase: Boolean,
    strong: Boolean,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
