import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PerformerRookieBadge = _resolveComponent("PerformerRookieBadge")!
  const _component_a_rate = _resolveComponent("a-rate")!

  return (_ctx.isRookie)
    ? (_openBlock(), _createBlock(_component_PerformerRookieBadge, {
        key: 0,
        class: "font-bold uppercase"
      }))
    : (_openBlock(), _createBlock(_component_a_rate, {
        key: 1,
        value: Number(_ctx.performer?.performerProfile?.rating),
        "allow-half": "",
        disabled: "",
        class: "leading-none"
      }, null, 8, ["value"]))
}