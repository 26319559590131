import gql from "graphql-tag";

export const removePerformerFromOrderGql = gql`
  mutation RemovePerformerFromOrder($input: RemovePerformerFromOrderInput!) {
    removePerformerFromOrder(input: $input) {
      ... on GenericSuccess {
        message
        success
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
