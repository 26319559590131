
import { PerformerDetails_performerDetails_PerformerDetails_performerProfile } from "@/api/performer/__generated__/PerformerDetails";
import { isPerformerRookie } from "@/shared/utils/performerProfileHelper";
import { PropType, computed, defineComponent } from "vue";
import PerformerRookieBadge from "@/shared/components/Performers/PerformerRookieBadge.vue";

type Performer = {
  performerProfile:
    | Pick<
        PerformerDetails_performerDetails_PerformerDetails_performerProfile,
        "rating" | "badges"
      >
    | undefined;
};

export default defineComponent({
  props: {
    performer: Object as PropType<Performer>,
  },
  setup(props) {
    const isRookie = computed(() =>
      isPerformerRookie(props.performer?.performerProfile)
    );
    return { isRookie };
  },
  components: { PerformerRookieBadge },
});
