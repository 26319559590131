
import { PerformerDetails_performerDetails_PerformerDetails_performerProfile } from "@/api/performer/__generated__/PerformerDetails";
import { RecommendedPerformers_recommendedPerformers_RecommendedPerformersResults_results_performerProfile } from "@/api/performer/__generated__/RecommendedPerformers";
import { Performers_performers_PerformerResults_results_performerProfile } from "@/api/performer/__generated__/Performers";
import IconLabel from "@/shared/components/IconLabel.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import {
  getPerformerBadgeData,
  isPerformerRookie,
  rookieBadgeIds,
} from "@/shared/utils/performerProfileHelper";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { orderByPriority } from "@/shared/utils/arrayUtils";

export default defineComponent({
  props: {
    performerProfile: {
      type: Object as PropType<
        | RecommendedPerformers_recommendedPerformers_RecommendedPerformersResults_results_performerProfile
        | Performers_performers_PerformerResults_results_performerProfile
        | PerformerDetails_performerDetails_PerformerDetails_performerProfile
        | null
      >,
      required: true,
    },
    forceUnlockedState: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const isLocked = computed<boolean>(
      () => !props.forceUnlockedState && !!props.performerProfile?.isLocked
    );

    /** Filtered badges (without Rookie). See: #865d2duhe */
    const badges = computed(() => {
      const badgesWithoutRookie =
        props.performerProfile?.badges?.filter(
          (badge) => !rookieBadgeIds.value.includes(badge?.id ?? "")
        ) ?? [];

      return orderByPriority(
        badgesWithoutRookie,
        [
          "Silver Performer",
          "Silver Creator",
          "Bronze Performer",
          "Bronze Creator",
        ],
        "name"
      );
    });

    const isRookie = computed(() => isPerformerRookie(props.performerProfile));

    return {
      t,
      getPerformerBadgeData,
      orderByPriority,
      isLocked,
      isRookie,
      badges,
    };
  },
  components: { IconLabel, AppIcon },
});
