import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "performer-rookie-badge flex items-center gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppIcon, {
      name: "starOutlined",
      class: "h-4"
    }),
    _createVNode(_component_a_typography_text, { class: "performer-rookie-badge__label text-color-2" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("New")), 1)
      ]),
      _: 1
    })
  ]))
}