import { useQuery } from "@vue/apollo-composable";
import { orderGql } from "@/api/order/order";
import { Ref, computed } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  Order,
  OrderVariables,
  Order_order_Order,
} from "@/api/order/__generated__/Order";
import { HideIfErrorCode } from "../utils/graphql/errorHandler";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import {
  AddPerformerToOrder,
  AddPerformerToOrderVariables,
  AddPerformerToOrder_addPerformerToOrder_GenericSuccess,
} from "@/api/order/__generated__/AddPerformerToOrder";
import { addPerformerToOrderGql } from "@/api/order/addPerformerToOrder";
import {
  ConfirmPerformersInOrder,
  ConfirmPerformersInOrderVariables,
  ConfirmPerformersInOrder_confirmPerformersInOrder_GenericSuccess,
} from "@/api/order/__generated__/ConfirmPerformersInOrder";
import { confirmPerformersInOrderGql } from "@/api/order/confirmPerformersInOrder";
import {
  RemovePerformerFromOrder,
  RemovePerformerFromOrderVariables,
  RemovePerformerFromOrder_removePerformerFromOrder_GenericSuccess,
} from "@/api/order/__generated__/RemovePerformerFromOrder";
import { removePerformerFromOrderGql } from "@/api/order/removePerformerFromOrder";
import {
  AddPerformerToOrderInput,
  ConfirmPerformersInOrderInput,
  RemovePerformerFromOrderInput,
} from "@/../__generated__/globalTypes";
import isEmpty from "lodash/isEmpty";
import { OrderStatus } from "@/shared/types/OrderStatus";
import clamp from "lodash/clamp";

/** List of OrderStatus where client can update performer list */
export const ORDER_STATUS_ALLOW_PERFORMER_UPDATE: OrderStatus[] = [
  OrderStatus.NEW,
  OrderStatus.APPROVED,
  OrderStatus.PAID,
  OrderStatus.SELECT_CREATOR,
];

/** List of OrderStatus where SelectedPerformersCard should be shown */
export const ORDER_STATUS_SHOW_SELECTED_PERFORMERS: OrderStatus[] = [
  OrderStatus.SELECT_CREATOR,
  OrderStatus.SHIP_PRODUCT,
  OrderStatus.IDEATION,
  OrderStatus.PRODUCTION,
  OrderStatus.QA,
  OrderStatus.CLIENT_REVIEW,
  OrderStatus.CLIENT_FEEDBACK,
  OrderStatus.DONE,
  OrderStatus.CANCELED,
];

export const ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS: OrderStatus[] = [
  OrderStatus.CLIENT_FEEDBACK,
  OrderStatus.DONE,
];

export const ORDER_STATUS_ALLOW_REVIEW_JOBS: OrderStatus[] = [
  OrderStatus.CLIENT_REVIEW,
];

export type UseOrderOptions = {
  orderId: Ref<string>;
  disabled?: Ref<boolean>;
};

export const useOrder = (options: UseOrderOptions) => {
  const {
    result: orderResult,
    loading: orderLoading,
    refetch: refetchOrder,
  } = useQuery<Order, OrderVariables>(
    orderGql,
    () => ({ input: { id: options.orderId.value } }),
    () => ({
      enabled: !!options.orderId.value && !options.disabled?.value,
    })
  );

  const orderParsedResponse = computed(() => {
    return parseGqlResponse<Order_order_Order>(
      "Order",
      orderResult.value,
      HideIfErrorCode.ALL_ERRORS
    );
  });

  const order = computed(() => orderParsedResponse.value.data);

  const selectedPerformers = computed(() => order.value?.performers ?? []);

  const performerApplications = computed(
    () =>
      order.value?.performerApplications.map(
        (application) => application.performer
      ) ?? []
  );

  // ===== Performer Add/Remove/Confirm(Submit) =====
  /** Fetch `order.performers` */
  const commonMutationOptions = {
    refetchQueries: [
      {
        query: orderGql,
        variables: <OrderVariables>{ input: { id: options.orderId.value } },
      },
    ],
  };

  const {
    mutate: addPerformerToOrderMutate,
    loading: addPerformerToOrderLoading,
  } = useCustomMutation<AddPerformerToOrder, AddPerformerToOrderVariables>(
    addPerformerToOrderGql,
    commonMutationOptions
  );

  const {
    mutate: removePerformerFromOrderMutate,
    loading: removePerformerFromOrderLoading,
  } = useCustomMutation<
    RemovePerformerFromOrder,
    RemovePerformerFromOrderVariables
  >(removePerformerFromOrderGql, commonMutationOptions);

  const {
    mutate: confirmPerformersInOrderMutate,
    loading: confirmPerformersInOrderLoading,
  } = useCustomMutation<
    ConfirmPerformersInOrder,
    ConfirmPerformersInOrderVariables
  >(confirmPerformersInOrderGql);

  // === Handlers ===
  const addPerformer = async (input: AddPerformerToOrderInput) => {
    const response = await addPerformerToOrderMutate({ input });

    const parsedResponse =
      parseGqlResponse<AddPerformerToOrder_addPerformerToOrder_GenericSuccess>(
        "GenericSuccess",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to add performer to latest order");
    }

    return parsedResponse.data;
  };

  const removePerformer = async (input: RemovePerformerFromOrderInput) => {
    const response = await removePerformerFromOrderMutate({ input });

    const parsedResponse =
      parseGqlResponse<RemovePerformerFromOrder_removePerformerFromOrder_GenericSuccess>(
        "GenericSuccess",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to Remove performer to latest order");
    }

    return parsedResponse.data;
  };

  const confirmPerformers = async (input: ConfirmPerformersInOrderInput) => {
    const response = await confirmPerformersInOrderMutate({ input });

    const parsedResponse =
      parseGqlResponse<ConfirmPerformersInOrder_confirmPerformersInOrder_GenericSuccess>(
        "GenericSuccess",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to confirm performers in latest order");
    }

    return parsedResponse.data;
  };

  // ===== UTILS =====
  /**
   * Gets remaining performer slot count based on currently selectedPerformers
   * @param maxCount Max limit of number of performers.
   * @returns The number of remaining slots, min: 0.
   */
  const getRemainingPerformerSlots = (maxCount: number): number => {
    return clamp(maxCount - selectedPerformers.value.length, 0, maxCount);
  };

  /** A boolean that returns true if OrderStatus <= SELECT_CREATOR */
  const canUpdatePerformerList = computed<boolean>(() => {
    // Only allow updating of performer list on specific OrderStatus values
    if (order.value && order.value.status) {
      return ORDER_STATUS_ALLOW_PERFORMER_UPDATE.includes(
        order.value.status.key as OrderStatus
      );
    }

    return false;
  });

  const canViewSelectedPerformers = computed<boolean>(() => {
    if (order.value && order.value.status) {
      return ORDER_STATUS_SHOW_SELECTED_PERFORMERS.includes(
        order.value.status.key as OrderStatus
      );
    }

    return false;
  });

  /**
   * Checks whether you can add more performers based on max limit.
   * @param maxCount Max limit of number of performers.
   * @returns A boolean.
   */
  const checkCanAddMorePerformers = (maxCount: number): boolean => {
    return selectedPerformers.value.length < maxCount;
  };

  const useVideoApprovalFeature = computed<boolean>(
    () => !order.value?.videoOutputLink
  );

  const isAllowedDownloadVideos = computed<boolean>(
    () =>
      useVideoApprovalFeature.value &&
      ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS.includes(
        order.value?.status?.key as OrderStatus
      )
  );

  return {
    order,
    orderLoading,
    refetchOrder,
    selectedPerformers,
    performerApplications,
    addPerformer,
    addPerformerToOrderLoading,
    removePerformer,
    removePerformerFromOrderLoading,
    confirmPerformers,
    confirmPerformersInOrderLoading,
    getRemainingPerformerSlots,
    canUpdatePerformerList,
    checkCanAddMorePerformers,
    canViewSelectedPerformers,
    isAllowedDownloadVideos,
    useVideoApprovalFeature,
  };
};
