import {
  Config,
  ConfigVariables,
  Config_config_Config,
} from "@/api/siteConfigs/__generated__/Config";
import { configGql } from "@/api/siteConfigs/config";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";

/** Get from BE */
export type SiteConfigName = "CONTENT_REQUEST_FORM_LINK" | "BADGE_ROOKIE_IDS";

export const useConfig = (name: SiteConfigName) => {
  const { result: configResult, loading: configLoading } = useQuery<
    Config,
    ConfigVariables
  >(
    configGql,
    () => ({ input: { name } }),
    () => ({ enabled: !!name })
  );

  const configValue = computed(() => {
    return parseGqlResponse<Config_config_Config>("Config", configResult.value)
      .data?.value;
  });

  return {
    configValue,
    configLoading,
  };
};
