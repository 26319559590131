import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }
const _hoisted_2 = { class: "performer-details-page__work-with-me" }
const _hoisted_3 = { class: "performer-details-page__button-note mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PerformerDetailsDisplay = _resolveComponent("PerformerDetailsDisplay")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "performer-details-page",
    "back-button": "",
    onOnBack: _ctx.$router.back
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PerformerDetailsDisplay, {
        "performer-details": _ctx.performerDetails,
        loading: _ctx.performerDetailsLoading
      }, {
        extra: _withCtx(() => [
          (
            _ctx.performerDetails?.performerProfile?.isPremium &&
            !_ctx.performerDetails?.performerProfile?.isLocked
          )
            ? (_openBlock(), _createBlock(_component_a_card, {
                key: 0,
                class: "performer-details-page__premium-card ml-auto my-8"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_AppIcon, { name: "diamondGold" }),
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.premiumPerformerCardText), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isPerformerButtonVisible)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_a_button, {
                    type: _ctx.isPerformerSelected ? 'secondary' : 'primary',
                    disabled: _ctx.isPerformerButtonDisabled,
                    onClick: _ctx.handlePerformerActionClick,
                    loading: 
                _ctx.addPerformerToOrderLoading || _ctx.removePerformerFromOrderLoading
              
                  }, {
                    default: _withCtx(() => [
                      (_ctx.performerDetails.performerProfile?.isLocked)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.t("Fully Booked")), 1)
                          ], 64))
                        : (_ctx.isPerformerSelected)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.t("Cancel Request")), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(_ctx.t("Request this model creator")), 1)
                            ], 64))
                    ]),
                    _: 1
                  }, 8, ["type", "disabled", "onClick", "loading"]),
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.showMaxPerformerNote)
                      ? (_openBlock(), _createBlock(_component_a_typography_text, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("You've already selected {current}/{max} Model Creators", {
                    current: _ctx.selectedPerformers.length,
                    max: _ctx.maxPerformerCount,
                  })), 1)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_a_typography_text, { key: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t(
                    "* This is still subject to the availability of the creator."
                  )), 1)
                          ]),
                          _: 1
                        }))
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["performer-details", "loading"])
    ]),
    _: 1
  }, 8, ["onOnBack"]))
}