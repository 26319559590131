import gql from "graphql-tag";

export const configGql = gql`
  query Config($input: ConfigInput!) {
    config(input: $input) {
      ... on Config {
        name
        value
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;
